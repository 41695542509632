import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { FaCalculator, FaAtom, FaHistory, FaBook, FaPaintBrush, FaMusic, FaGlobe, FaMicroscope, FaFlask, FaLaptopCode, FaLanguage, FaFileAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Define icon mapping
const iconMap = {
    'الفيزياء الميكانيكية': <FaMicroscope size={ 20 } className="text-orange-600" />,
    'الكيمياء': <FaFlask size={ 20 } className="text-cyan-600" />,
    'الأحياء': <FaAtom size={ 20 } className="text-green-600" />,
    'الرياضيات': <FaCalculator size={ 20 } className="text-indigo-600" />,
    'التربية الإسلامية': <FaBook size={ 20 } className="text-teal-600" />,
    'الدراسات الأدبية': <FaBook size={ 20 } className="text-red-600" />,
    'تقنية المعلومات': <FaLaptopCode size={ 20 } className="text-blue-600" />,
    'الدراسات اللغوية': <FaLanguage size={ 20 } className="text-purple-600" />,
    'اللغة الإنجليزية': <FaLanguage size={ 20 } className="text-purple-600" />,
    'أسس الإحصاء': <FaFileAlt size={ 20 } className="text-gray-600" />,
};
export default function SubjectsCard({ title, id, imgSrc, description })
{
    const navigate = useNavigate();
    return (
        <Card onClick={ () => navigate(`/subjects/${id}`) } className="card-container w-full max-w-md rounded-lg overflow-hidden shadow-lg hover:cursor-pointer hover:shadow-2xl relative">
            <CardMedia
                component="img"
                image={ imgSrc }
                alt={ title }
                className="card-image max-h-88 min-h-48 object-cover rounded-t-lg"
                style={ {
                    width: '100%', // Make sure the image covers the card width
                    height: '200px', // Fixed height for uniform size
                    objectFit: 'cover', // Ensure the image covers the area without distortion
                } }
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-30 rounded-t-lg"></div>
            <CardContent className="card-content p-6 text-center relative z-10 flex flex-col gap-4 h-full min-h-56">
                <div className="absolute right-4 top-4">
                    { iconMap[title] || <FaBook size={ 40 } className="text-gray-600" /> } {/* Default icon if not found */ }
                </div>
                <div className="">

                    <Typography variant="h5" component="div" className="font-bold text-xl  text-indigo-700 text-wrap text-center ">
                        { title }
                    </Typography>
                </div>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className="mb-4 text-lg leading-relaxed"
                >
                    { description }
                </Typography>

                <Button variant="contained" sx={ { position: 'absolute', bottom: '10%', left: '50%', transform: 'translateX(-50%)', minWidth: '12rem' } } color="primary" >
                    المزيد
                </Button>
                <div className="absolute bottom-0 left-0 w-full bg-gray-200 h-2 rounded-b-lg">
                    <div className="bg-indigo-600 h-full" style={ { width: '80%' } }></div>
                </div>
            </CardContent>
        </Card>
    );
}
