import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import
{
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ErrorPage from './error-page';
import SignInPage from './routes/SignInPage';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
// import { createTheme } from '@mui/material/styles';
import { blue, teal, amber, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import Layout from './components/layout';
import SignUpPage from './routes/SignUpPage';
import SubjectPage from './routes/SubjectsPage';
import UploadForm from './components/UploadForm';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />, // Use Layout here
    children: [
      {
        path: '',
        element: <App />,
      },
      {
        path: 'signin',
        element: <SignInPage />,
      },
      {
        path: 'signup',
        element: <SignUpPage />
      },
      {
        path: 'subjects/:id', // Define the route for subjects
        element: <SubjectPage />, // Component to display subject content
      },
      {
        path: '/admin',
        element: <UploadForm />
      },
      // Add more child routes here
    ],
  },
]);

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Cairo, sans-serif', // Ensure the font is loaded in your project
  },
  palette: {
    primary: {
      main: blue[500], // Primary color: Blue (trustworthy and calm)
    },
    secondary: {
      main: teal[500], // Secondary color: Teal (fresh and inviting)
    },
    text: {
      primary: grey[900], // Dark grey for readable text
      secondary: grey[600], // Lighter grey for secondary text
    },
    background: {
      default: '#f5f5f5', // Light grey background for a soft look
      paper: '#ffffff', // White background for Paper components
    },
    error: {
      main: amber[500], // Amber for error notifications (noticeable but not harsh)
    },
    warning: {
      main: amber[700], // Darker amber for warnings (still visible but less alarming)
    },
    info: {
      main: blue[300], // Light blue for informational elements
    },
    success: {
      main: teal[300], // Light teal for success messages
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={ theme }>
    <CacheProvider value={ cacheRtl }>
      <RouterProvider router={ router } />
    </CacheProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
