import React from 'react';
import { Box, TextField, IconButton, InputAdornment, useTheme } from '@mui/material';
import { FaSearch } from 'react-icons/fa';

const SearchField = ({ searchTerm, setSearchTerm }) =>
{
    const theme = useTheme(); // Correctly call useTheme at the top level

    return (
        <Box mb={ 4 }>
            <TextField
                variant="outlined"
                fullWidth
                placeholder="ابحث عن فيديو..."
                value={ searchTerm } // Use the searchTerm passed as prop
                onChange={ (e) => setSearchTerm(e.target.value) } // Call setSearchTerm from props
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <FaSearch />
                            </IconButton>
                        </InputAdornment>
                    ),
                } }
                sx={ {
                    backgroundColor: theme.palette.background.paper, // Use theme background color
                    borderRadius: 2,
                    '& .MuiInputBase-input': {
                        padding: '10px 12px', // Padding inside the input
                    },
                } }
            />
        </Box>
    );
};

export default SearchField;
