import React from 'react';
import { LuLoader2 } from "react-icons/lu";
import { Controller, useForm } from "react-hook-form";
import { Toaster, toast } from 'sonner';
import { Button, Card, CardContent, TextField, MenuItem, Select, InputLabel, FormControl, useTheme } from '@mui/material';
import instance from '../axios';
import { useNavigate } from 'react-router-dom';

export default function SignUpPage()
{
    const [spinner, setSpinner] = React.useState(false);
    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
            fullName: '',         // New field for full name
            phoneNumber: '',       // New field for phone number
            educationLevel: '',    // New select for education level
            gender: '',            // New select for gender
            cardNumber: '',        // New field for card number
        },
    });

    const navigate = useNavigate();
    const theme = useTheme();

    const onSubmit = async (data) =>
    {
        setSpinner(true);
        if (data.password !== data.confirmPassword)
        {
            toast.error('كلمات السر غير متطابقة');
            setSpinner(false);
            return;
        }

        const res = await instance.post(`/signup`, data, {
            withCredentials: true,
        }).then((res) =>
        {
            toast.success('تم تسجيل الحساب بنجاح');
            console.log('res', res);
            setSpinner(false);
            // navigate('/signin');
        }).catch((error) =>
        {
            console.log(error)
    const errorMessage = error.response?.data?.message || 'لقد حدث خطأ ما'; // Default message in Arabic

    // Show the error toast
            toast.error(errorMessage);
            setSpinner(false);
            console.log('error', error);
        });
    };

    return (
        <div style={ {
            background: 'linear-gradient(135deg, #00d2ff, #3a7bd5)',
        } } className={ ` h-full items-center lg:fixed top-0 right-0 bottom-0 left-0 z-50 flex flex-col` }>
            <div className="flex flex-col w-full items-center justify-center max-h-5/6 min-h-fit m-auto">
                <Toaster />
                <Card className="lg:w-2/3 w-5/6 min-h-fit m-16 lg:m-0">
                    <CardContent sx={ { padding: '0px', paddingBottom: '0px', '&:last-child': { paddingBottom: '0px' } } } className="flex lg:flex-row flex-col-reverse items-center lg:relative h-full w-full lg:overflow-hidden rtl:last:pb-0 muirtl-MuiCardContent-root:last:pb-0">
                        <div className="flex flex-col  w-full items-center gap-6 p-auto p-4 h-full">
                            <div className='flex flex-col  text-center items-center  w-full h-full'>
                                <img src='/logo.png' alt="الشعار" className="w-20 h-20 cursor-pointer" onClick={ () => navigate('/') } /> {/* Adjust size as needed */ }
                                <h3 className="text-2xl">تسجيل حساب جديد</h3>
                            </div>
                            <form onSubmit={ handleSubmit(onSubmit) } className="space-y-6 w-full m-auto px-8">
                                <div className="flex flex-col lg:flex-row gap-6">
                                    {/* Username */ }


                                    {/* Full Name */ }
                                    <div className="flex-[5]">
                                        <Controller
                                            name="fullName"
                                            control={ control }
                                            rules={ { required: true } }
                                            render={ ({ field }) =>
                                                <TextField
                                                    dir='rtl'
                                                    { ...field }
                                                    label="الإسم بالكامل"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                />
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-6">
                                    {/* Phone Number */ }
                                    <div className="flex-[4]">
                                        <Controller
                                            name="phoneNumber"
                                            control={ control }
                                            rules={ { required: true } }
                                            render={ ({ field }) =>
                                                <TextField
                                                    dir='rtl'
                                                    { ...field }
                                                    label="رقم الهاتف"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                />
                                            }
                                        />
                                    </div>

                                    {/* Education Level - Select Dropdown */ }
                                    <div className="flex-[4]">
                                        <Controller
                                            name="educationLevel"
                                            control={ control }
                                            rules={ { required: true } }
                                            render={ ({ field }) => (
                                                <FormControl fullWidth size="small" required>
                                                    <InputLabel id="education-select-label">ثالث ثانوي</InputLabel>
                                                    <Select
                                                        { ...field }
                                                        labelId="education-select-label"
                                                        label="ثالث ثانوي"
                                                    >
                                                        <MenuItem value="أول ثانوي">أول ثانوي</MenuItem>
                                                        <MenuItem value="ثاني ثانوي">ثاني ثانوي</MenuItem>
                                                        <MenuItem value="ثالث ثانوي">ثالث ثانوي</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            ) }
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-6">
                                    {/* Gender - Select Dropdown */ }
                                    <div className="flex-[4]">
                                        <Controller
                                            name="gender"
                                            control={ control }
                                            rules={ { required: true } }
                                            render={ ({ field }) => (
                                                <FormControl fullWidth size="small" required>
                                                    <InputLabel id="gender-select-label">الجنس</InputLabel>
                                                    <Select
                                                        { ...field }
                                                        labelId="gender-select-label"
                                                        label="الجنس"
                                                    >
                                                        <MenuItem value="male">ذكر</MenuItem>
                                                        <MenuItem value="female">أنثى</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            ) }
                                        />
                                    </div>

                                    {/* Card Number */ }
                                    <div className="flex-[6]">
                                        <Controller
                                            name="cardNumber"
                                            control={ control }
                                            rules={ { required: true } }
                                            render={ ({ field }) =>
                                                <TextField
                                                    dir='rtl'
                                                    { ...field }
                                                    label="رقم الكرت"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    required
                                                />
                                            }
                                        />
                                    </div>
                                </div>

                                <Controller
                                    name="password"
                                    control={ control }
                                    rules={ { required: true } }
                                    render={ ({ field }) =>
                                        <TextField
                                            { ...field }
                                            label="كلمة السر"
                                            type="password"
                                            size="small"
                                            fullWidth
                                            required
                                        />
                                    }
                                />

                                <Controller
                                    name="confirmPassword"
                                    control={ control }
                                    rules={ { required: true } }
                                    render={ ({ field }) =>
                                        <TextField
                                            { ...field }
                                            label="تأكيد كلمة السر"
                                            type="password"
                                            size="small"
                                            fullWidth
                                            required
                                        />
                                    }
                                />

                                <div className="flex gap-8">
                                    <Button variant="contained" className="bg-green-500" type="submit">أنشئ حسابك!</Button>
                                    { spinner && <LuLoader2 className="h-8 w-8 animate-spin" /> }
                                </div>
                            </form>


                            <div className="w-full h-full flex flex-col items-center">
                                <h4>لديك حساب بالفعل؟</h4>
                                <h4 onClick={ () => navigate('/signin') } style={ { color: theme.palette.primary.main } } className={ `hover:opacity-100 opacity-80 hover:cursor-pointer transition-colors` }>تسجيل الدخول هنا</h4>
                            </div>
                        </div>
                        <div className="lg:relative flex mb-8 lg:mb-0 bg-zinc-200 lg:bottom-0 lg:top-0  lg:h-full w-full border-4 border-zinc-200   ">
                            <img
                                style={ { position: 'static' } }
                                className="static object-cover lg:object-cover"
                                src="signup-img.jpg"
                                alt="صورة للتسجيل"
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
