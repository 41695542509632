import { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography, FormControl, InputLabel, MenuItem, Select, IconButton } from '@mui/material';
import instance from '../axios';
import DeleteIcon from '@mui/icons-material/Delete';

function SubjectVideoManager()
{
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [videos, setVideos] = useState([]);

    // Fetch subjects on component mount
    useEffect(() =>
    {
        instance.get('/subjects')
            .then(response =>
            {
                setSubjects(response.data);
            })
            .catch(error =>
            {
                console.error('Failed to fetch subjects:', error);
            });
    }, []);

    // Fetch videos when a subject is selected
    const handleSubjectChange = (e) =>
    {
        const subjectId = e.target.value;
        setSelectedSubject(subjectId);

        // Fetch videos for the selected subject
        instance.get(`/subjects/${subjectId}`)
            .then(response =>
            {
                setVideos(response.data.videos);
            })
            .catch(error =>
            {
                console.error('Failed to fetch videos:', error);
            });
    };

    // Delete a video
    const handleDeleteVideo = (videoId) =>
    {
        instance.delete(`/videos/${videoId}`)
            .then(response =>
            {
                alert('Video deleted successfully');
                // Remove the deleted video from the state
                setVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
            })
            .catch(error =>
            {
                console.error('Failed to delete video:', error);
            });
    };

    return (
        <div
       
            className="p-2 flex  justify-center mt-8"
        >
            <div className="lg:w-2/3 w-full  ">
                <div className="flex flex-col gap-4 ">
                    <h2 className="text-4xl text-center">إدارة الحصص</h2>
                    <FormControl fullWidth required variant="outlined">
                        <InputLabel>اختيار المادة</InputLabel>
                        <Select
                            value={ selectedSubject }
                            onChange={ handleSubjectChange }
                            label="اختيار المادة"
                            className="bg-white"
                            MenuProps={ {
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                    },
                                },
                            } }
                        >
                            { subjects.map(subject => (
                                <MenuItem key={ subject.id } value={ subject.id }>
                                    { subject.arabicTitle } - { subject.englishTitle }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>

                    { videos.length > 0 ? (
                        <div className="space-y-4">
                            <Typography variant="h5">الحصص:</Typography>
                            { videos.map((video) => (
                                <Card key={ video.id } className="p-4">
                                    <CardContent className="flex items-center justify-between">
                                        <div>
                                            <Typography variant="h6">{ video.title }</Typography>
                                            <Typography variant="body2">{ video.description }</Typography>
                                        </div>
                                        <IconButton
                                            color="error"
                                            onClick={ () => handleDeleteVideo(video.id) }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardContent>
                                </Card>
                            )) }
                        </div>
                    ) : (
                        <Typography variant="body1">لا يوجد حصص لهذه المادة.</Typography>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default SubjectVideoManager;
