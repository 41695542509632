import { useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import instance from '../axios';
import SubjectsForm from './SubjectsForm';
import SubjectVideos from './SubjectVideoManager';
import SubjectVideoManager from './SubjectVideoManager';
import UserTable from './UsersTable';
import CardGenerator from './CardGenerator';

function UploadForm()
{
    const [video, setVideo] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        subject: '',
        duration: '',
        
    });
    const videoRef = useRef(null);
    const [subjects, setSubjects] = useState([]);



    useEffect(() =>
    {
        // Fetch subjects from the server
        instance.get('/subjects')
            .then(response =>
            {
                setSubjects(response.data);
            })
            .catch(error =>
            {
                console.error('Failed to fetch subjects:', error);
            });
    }, []);

    const handleSubjectChange = (e) =>
    {
        setFormData(prev => ({ ...prev, subject: e.target.value }));
    };

    const handleFileChange = (e) =>
    {
        const file = e.target.files[0];
        setVideo(file);
        console.log(file);
        if (file && videoRef.current)
        {
            const url = URL.createObjectURL(file);
            videoRef.current.src = url;

            videoRef.current.onloadeddata = () =>
            {
                const duration = videoRef.current.duration;
                if (duration)
                {
                    console.log('duration', duration);
                    setFormData(prev => ({ ...prev, duration: duration.toFixed(2) }));
                } else
                {
                    console.error('Failed to retrieve video duration.');
                }
                URL.revokeObjectURL(url); // Clean up the URL object
            };

            videoRef.current.onerror = () =>
            {
                console.error('Error loading video file.');
                URL.revokeObjectURL(url); // Clean up the URL object in case of error
            };
        }
    };

    const handleInputChange = (e) =>
    {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };




    const uploadFile = () =>
    {
        if (!video) return Promise.reject(new Error('No video selected'));

        const fileFormData = new FormData();
        fileFormData.append('video', video);
    

        return instance.post('/uploadFile', fileFormData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response =>
            {
                console.log('File upload response:', response.data);
                return response.data.filePath; // Return the filePath
            })
            .catch(error =>
            {
                alert(error);
                console.error('File upload failed:', error);
                return Promise.reject(error); // Forward the error to the next catch
            });
    };

    const submitFormData = (filePath) =>
    {
        const data = {
            ...formData,

            filePath,

        };

        return instance.post('/uploadData', data, {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response =>
            {
                console.log('Form data response:', response.data);
            })
            .catch(error =>
            {
                alert(error);
                console.error('Form data submission failed:', error);
                return Promise.reject(error); // Forward the error to the next catch
            });
    };

    const handleSubmit = (e) =>
    {
        e.preventDefault();
        setUploading(true);

        uploadFile()
            .then(uploadedFilePath => submitFormData(uploadedFilePath).catch(err => alert(err)))
            .then(() => setFilePath(filePath))
            .catch(error =>
            {
                alert('Upload process failed: ' + error);
                console.error('Upload process failed:', error);
            }
            )
            .finally(() => setUploading(false));
    };

    // const handleSubmit = async (e) =>
    // {
    //     e.preventDefault();
    //     // if (!video) return;
    //     console.log('video', video);

    //     const formDataToSend = new FormData();
    //     formDataToSend.append('video', video);
    //     formDataToSend.append('title', formData.title);
    //     formDataToSend.append('description', formData.description);
    //     formDataToSend.append('subject', formData.subject);

    //     setUploading(true);

    //     try
    //     {
    //         console.log('formData', formDataToSend);
    //         const response = await axios.post('http://localhost:5000/upload', formDataToSend, {
    //             headers: { 'Content-Type': 'multipart/form-data' }
    //         }).then((res) => console.log(res)).catch((err) => console.log(err));
    //         setFilePath(response.data.filePath);
    //     } catch (error)
    //     {
    //         console.error('Upload failed:', error);
    //     } finally
    //     {
    //         setUploading(false);
    //     }
    // };

    return (
        <div
            style={ {
                background: 'linear-gradient(135deg, #00d2ff, #3a7bd5)',
            } }
            className="min-h-screen pt-12 flex items-center justify-center"
        >
            <Card className="lg:w-2/3 w-5/6 ">
                <CardContent className="flex flex-col gap-4 p-8">
                    <h2 className="text-4xl text-center">رفع فيديو</h2>
                    <form onSubmit={ handleSubmit } className="space-y-6">
                        <TextField
                            label="عنوان الفيديو"
                            name="title"
                            value={ formData.title }
                            onChange={ handleInputChange }
                            fullWidth
                            required
                        />
                        <TextField
                            label="الوصف"
                            name="description"
                            value={ formData.description }
                            onChange={ handleInputChange }
                            fullWidth
                            multiline
                            rows={ 4 }
                            required
                        />
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel>المادة</InputLabel>
                            <Select
                                value={ formData.subject }
                                onChange={ handleSubjectChange }
                                name="subject"
                                label="المادة"
                                className="bg-white"
                                MenuProps={ {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                } }
                            >
                                { subjects.map(subject => (
                                    <MenuItem key={ subject.id } value={ subject.id }>
                                        { subject.arabicTitle }
                                    </MenuItem>
                                )) }
                            </Select>
                        </FormControl>
                        <input

                            type="file"
                            accept="video/*"
                            onChange={ handleFileChange }
                            className="block w-full"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={ uploading }
                        >
                            { uploading ? 'جاري التحميل...' : 'حمل الآن' }
                        </Button>
                    </form>

                    <div className="mt-4">
                        <h3 className="text-xl mb-4"> الفيديو المحمل:</h3>
                        <video ref={ videoRef } width="320" height="240" controls>
                            <source src={ `${process.env.NODE_ENV === 'production' ?'102.213.183.75' : process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '127.0.0.1:8080'} 
${filePath}` } type="video/mp4" />
                       
                        </video>
                    </div>
                    <SubjectsForm />
                    <SubjectVideoManager />
                    <UserTable />
                 <CardGenerator /> 
                </CardContent>
            </Card>
        </div>
    );
}

export default UploadForm;
