import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Typography, Card, CardContent, Button, Grid, Breadcrumbs, Box, CircularProgress } from '@mui/material';
import { FaPlayCircle, FaStar } from 'react-icons/fa';
import AdditionalResources from '../components/AdditionalResources';
import SearchField from '../components/SearchField';
import instance from '../axios';

const SubjectPage = () =>
{
    const { id } = useParams();
    const [subject, setSubject] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    console.log('id:subject', id);
    useEffect(() =>
    {
        const fetchSubjects = async () =>
        {
            await instance.get(`subjects/${id}`).then((res) =>
            {
                console.log(res);
                setSubject(res.data);
            });
        };
        fetchSubjects();
    }, [id]);

    if (!subject)
    {
        return (
            <Box
                sx={ {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    textAlign: 'center',
                    mt: '2rem',
                } }
            >
                <CircularProgress color="primary" size={ 60 } />
                <Typography
                    variant="h4"
                    sx={ {
                        fontWeight: '600',
                        mt: 4,
                    } }
                >
                    تحميل المحتوى...
                </Typography>
            </Box>
        );
    }

    const filteredVideos = subject.videos.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container sx={ { paddingBottom: 6 } }>
            {/* Hero Section */ }
            <Box
                sx={ {
                    background: 'linear-gradient(135deg, #00d2ff, #3a7bd5)',
                    color: '#fff',
                    borderRadius: 2,
                    p: 4,
                    textAlign: 'center',
                    mb: 4,
                    mt: 6,
                    gap: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                } }
            >
                <Typography
                    variant="h1"
                    component="h1"
                    style={ {
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                        fontSize: '2.5rem',
                        lineHeight: 1.2,
                    } }
                    gutterBottom
                >
                    { subject.arabicTitle }
                </Typography>
                <Typography variant="h6" paragraph>
                    { subject.description }
                </Typography>
                <Typography variant="h6" style={ { display: 'flex', alignItems: 'center', gap: 12 } }>
                    <FaStar style={ { color: 'gold' } } /> { subject.level } | { subject.duration }
                </Typography>
            </Box>

            {/* Breadcrumbs */ }
            <Breadcrumbs aria-label="breadcrumb" mb={ 4 }>
                <Link to="/" style={ { textDecoration: 'none', color: '#3f51b5' } }>الرئيسية</Link>
                <Typography color="textPrimary">{ subject.arabicTitle }</Typography>
            </Breadcrumbs>

            {/* Search Field */ }
            <SearchField searchTerm={ searchTerm } setSearchTerm={ setSearchTerm } />

            {/* Videos Section */ }
            { filteredVideos.length > 0 ? (
                <Grid container spacing={ 4 }>
                    { filteredVideos.map((video, index) => (
                        <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ index }>
                            <Card elevation={ 4 } style={ { borderRadius: '12px', overflow: 'hidden' } }>
                                <CardContent>
                                    <Box mb={ 2 } textAlign="center">
                                        <img
                                            src={ video.thumbnailUrl || subject.imageUrl }
                                            alt={ video.title }
                                            className='bg-zinc-200 h-48 object-cover rounded-t-lg'
                                            style={ { width: '100%', borderRadius: '8px', cursor: 'pointer' } }
                                            onClick={ () => window.open(video.url, '_blank') }
                                        />
                                    </Box>
                                    <Typography variant="h6" gutterBottom>
                                        { video.title }
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        { video.description }
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={ <FaPlayCircle /> }
                                        fullWidth
                                        style={ { marginTop: '16px' } }
                                        onClick={ () => window.open(video.url, '_blank') }
                                    >
                                        مشاهدة الفيديو
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    )) }
                </Grid>
            ) : (
                <Typography variant="h6" align="center" color="textSecondary">
                    لا توجد فيديوهات تطابق بحثك.
                </Typography>
            ) }

            {/* Additional Resources */ }
            <AdditionalResources curriculumUrl={ subject.curriculumPdfPath } testUrl={ subject.testPdfPath } answersUrl={ subject.answerPdfPath } />
        </Container>
    );
};

export default SubjectPage;
