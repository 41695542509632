import React, { useEffect, useState } from 'react';
import axios from 'axios';
import instance from '../axios';

const UserTable = () =>
{
  const [users, setUsers] = useState([]);

  useEffect(() =>
  {
    // Fetch users from the Express API
    const fetchUsers = async () =>
    {
      try
      {
        const response = await instance.get('/users');
        setUsers(response.data);
      } catch (error)
      {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
console.log(users)
  return (
   <div style={{ overflowX: 'auto' }}>
  <table className="table-auto border-collapse w-full" border="1" cellPadding="10" cellSpacing="0">
    <thead>
      <tr>
        <th>رقم الكرت</th>
        <th>البريد الإلكتروني</th>
        <th>الاسم</th>
        <th>رقم الهاتف</th>
        <th>المستوى التعليمي</th>
        <th>الجنس</th>
        <th>تاريخ الإنشاء</th>
      </tr>
    </thead>
    <tbody className="overflow-scroll">
      {users.map((user) => (
        <tr key={user.id}>
          <td>{user?.Card?.cardNumber}</td>
          <td>{user.email}</td>
          <td>{user.name}</td>
          <td>{user.phoneNumber}</td>
          <td>{user.educationLevel}</td>
          <td>{user.gender}</td>
          <td>{new Date(user.createdAt).toLocaleString('ar-EG')}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
  );
};

export default UserTable;
