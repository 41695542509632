import React, { useState, useEffect } from 'react';

import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import instance from '../axios';

const useScrollPosition = () =>
{

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() =>
    {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollPosition;
};


const Header = () =>
{
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Auth state
    const navigate = useNavigate();

    const handleSignUp = () =>
    {
        navigate('/signup'); // Update the route according to your signup page
    };
    useEffect(() =>
    {
        // Fetch the authentication status on component mount
        const checkAuthStatus = async () =>
        {
            try
            {
                const response = await instance.get('/auth-status', {
                    credentials: 'include', // Include cookies to check session
                });
                console.log(response);
                setIsAuthenticated(response.data.authenticated);
            } catch (error)
            {
                console.error('Error checking auth status:', error);
            }
        };

        checkAuthStatus();
    }, []);

    const handleLogIn = () =>
    {
        navigate('/signin'); // Update the route according to your login page
    };

    const location = useLocation();

    const scrollY = useScrollPosition();
    const handleLogout = async () =>
    {
        try
        {
            await instance.post('/logout', {
                credentials: 'include', // Include cookies to log out the session
            });
            setIsAuthenticated(false); // Set auth state to false after logout
            navigate('/signup'); // Redirect to home after logout
        } catch (error)
        {
            console.error('Error logging out:', error);
        }
    };

    return (
        <header>
            <AppBar
                position="fixed"
                style={ {
                    background: scrollY < 450 && location.pathname === '/' ? 'transparent' : 'linear-gradient(135deg, #00d2ff, #3a7bd5)',
                    boxShadow: 'none',
                } }
            >
                <Toolbar>
                    <div className='flex justify-between items-center w-full max-w-6xl gap-2 m-auto  md:px-6'>
                        <div className='flex gap-2 md:gap-10'>
                            { !isAuthenticated ? (
                                <>
                                    {/* Show Sign Up and Log In buttons when not authenticated */ }
                                    <Button style={{textWrap: 'nowrap'}} color="inherit" variant='outlined' onClick={ handleSignUp }>
                                        سجل معنا !
                                    </Button>
                                    <Button style={{textWrap: 'nowrap'}} variant='outlined' color="inherit" onClick={ handleLogIn }>
                                        تسجيل الدخول
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {/* Show Logout button when authenticated */ }
                                    <Button color="inherit" variant='outlined' onClick={ handleLogout }>
                                        تسجيل الخروج
                                    </Button>
                                    <Button variant='outlined' color="inherit" onClick={ () => navigate('/profile') }>
                                        حسابي
                                    </Button>
                                </>
                            ) }
                        </div>
                        <div className="flex items-center  gap-2 md:gap-8">
                            <p className="text-lg md:text-3xl text-nowrap  text-white cursor-pointer" onClick={ () => navigate('/') }>
                                منصة رمزي
                            </p>
                            <img src='/logo.png' alt="Logo" className="w-10 h-10 md:w-12 md:h-12 cursor-pointer" onClick={ () => navigate('/') } /> {/* Adjust size as needed */ }
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </header>
    );
};

export default Header;
