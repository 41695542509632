import React from 'react';

import { LuLoader2 } from "react-icons/lu";
import { Controller, useForm } from "react-hook-form";
import { Toaster, toast } from 'sonner';
import { Button, Card, CardContent, TextField, useTheme } from '@mui/material';
import axios from 'axios';
import instance from '../axios';
import { redirect, useNavigate } from 'react-router-dom';

export default function SignInPage()
{
    const [spinner, setSpinner] = React.useState(false);
    // const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            phoneNumber: '',
            password: '',
        },
    });

    const navigate = useNavigate();

    const onSubmit = async (data) =>
    {

        setSpinner(true);
        console.log('data: {phoneNumber, password}', data);
        const res = await instance.post(`/login`, data, {
            withCredentials: true,

        }).then((res) =>
        {
            toast.success('تم تسجيل الدخول بنجاح');
            console.log('res', res);
            navigate('/');
        });


    };

    const theme = useTheme();
    return (
        <div style={ {
            background: 'linear-gradient(135deg, #00d2ff, #3a7bd5)',
        } } className={ ` min-h-screen h-full md:fixed top-0 right-0 bottom-0 left-0 z-50 flex  flex-col` }>
            {/* <PageTitle title="بوابة الدخول" /> */ }
            <div className="flex flex-col 
                   w-full  items-center justify-center h-full my-auto md:my-0 ">
                <Toaster />
                <Card className="md:w-2/3 w-5/6 h-3/4 mb-0 pb-0 ">

                    <CardContent sx={ { padding: '0px', paddingBottom: '0px', '&:last-child': { paddingBottom: '0px' } } } className="flex md:flex-row flex-col-reverse items-center  md:relative h-full w-full md:overflow-hidden  rtl:last:pb-0 muirtl-MuiCardContent-root:last:pb-0">



                        <div className="flex flex-col md:w-1/2 w-full items-center gap-12 mb-16 md:mb-0">
                            <div className='flex flex-col text-center w-full  h-full'>
                                <img src='/logo.png' alt="الشعار" className=" h-20 w-20 m-auto" />
                                <h3 className="text-2xl">تسجيل الدخول</h3>
                            </div>
                            <form onSubmit={ handleSubmit(onSubmit) } className="space-y-6 w-full m-auto  px-8  ">
                                <Controller
                                    name="username"
                                    control={ control }
                                    rules={ { required: true } }
                                    render={ ({ field }) =>
                                        <TextField
                                            dir='rtl'
                                            { ...field }
                                            label="رقم الهاتف"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            required
                                        />
                                    }
                                />
                                <Controller
                                    name="password"
                                    control={ control }
                                    rules={ { required: true } }
                                    render={ ({ field }) =>
                                        <TextField
                                            { ...field }
                                            label="كلمة السر"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            required
                                        />
                                    }
                                />
                                <div className="flex gap-8">
                                    <Button variant="contained" className="bg-green-500" type="submit">الدخول</Button>
                                    { spinner &&
                                        <LuLoader2 className="h-8 w-8 animate-spin " />
                                    }
                                </div>
                            </form>
                            <div className="w-full h-full flex flex-col items-center">

                                <h4>ليس لديك حساب؟</h4>

                                <h4
                                    onClick={ () => navigate('/signup') }
                                    style={ { color: theme.palette.primary.main } } className={ `hover:opacity-100 opacity-80 hover:cursor-pointer transition-colors` }>سجل معنا حسابك اليوم</h4>

                            </div>
                        </div>
                        <div className="md:relative   mb-8 md:mb-0 bg-zinc-200  h-48 hidden md:flex md:h-full md:aspect-square ">


                            <img
                                // priority
                                // className="w-1/3"
                                // objectFit={ "fill" }
                                // quality={ 95 }
                                style={ { position: 'static' } }
                                className="static "
                                src="/signin-img.jpg"
                                alt="صورة للكورسات"

                            // width={ 600 }
                            // height={ 600 }
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div >
    );
};
