// Layout.js
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';

const useScrollPosition = () =>
{
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() =>
    {
        const handleScroll = () => setScrollPosition(window.scrollY);
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollPosition;
};

const Layout = () =>
{
    const scrollY = useScrollPosition();

    const location = useLocation();

    let isHomeAndScrolledDown = location.pathname === '/' && scrollY > 25;
    return (
        <div>
            { location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/' ? <Header /> : isHomeAndScrolledDown && <Header /> }
            {/* <Header /> */ }
            <main style={ { paddingTop: location.pathname !== '/' && location.pathname !== '/signin' && location.pathname !== '/signup' && '55px' } } className=" bg-gradient-to-br from-purple-100 to-blue-100">
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;
