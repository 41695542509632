import React from 'react';
import { Box, Typography, Divider, Grid, Card, CardContent, Button, useTheme } from '@mui/material';

// Define your resources
const AdditionalResources = ({ curriculumUrl, answersUrl, testUrl }) =>
{
    const resources = [
        {
            title: "تحميل المنهج",
            description: "احصل على نسخة من المنهج الدراسي كاملاً بصيغة PDF. يشمل المنهج جميع المواضيع التي ستتعلمها خلال الدورة.",
            buttonText: "تحميل المنهج",
            buttonColor: "primary",
            link: curriculumUrl,
        },
        {
            title: "حل الأسئلة",
            description: "قم بحل الأسئلة والتدريبات المرتبطة بالدروس. تساعدك هذه الأسئلة على التحقق من فهمك وتطبيق ما تعلمته.",
            buttonText: "حل الأسئلة",
            buttonColor: "secondary",
            link: answersUrl
        },
        {
            title: "اختبر نفسك",
            description: "استخدم هذه الاختبارات القصيرة لاختبار مدى فهمك للمواد. تقدم لك الاختبارات فرصة لتقييم تقدمك والتعرف على المجالات التي تحتاج إلى تحسين.",
            buttonText: "اختبر نفسك",
            buttonColor: "success",
            link: testUrl
        }
    ];

    const theme = useTheme();

    const handleDownload = (link) =>
    {
        const a = document.createElement('a');
        a.href = link;
        a.download = ''; // Optional: You can specify a filename here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <Box mt={ 6 }>
            <Typography variant="h3"
                style={ {
                    fontWeight: '550',
                    color: theme.palette.text.primary
                } }
                gutterBottom>
                موارد إضافية
            </Typography>
            <Divider sx={ { mb: 4 } } />

            <Grid container spacing={ 4 }>
                { resources.map((resource, index) => (
                    <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ index }>
                        <Card
                            elevation={ 6 }
                            sx={ {
                                borderRadius: 2,

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%', // Fixed height for uniformity
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Enhanced shadow for visual separation
                                position: 'relative', // Needed for absolute positioning of the button
                                '&:hover': {
                                    boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)' // Shadow on hover
                                }
                            } }
                        >
                            <CardContent sx={ {
                                marginBottom: '40px',
                                flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', p: 4
                            } }>
                                <Typography variant="h6" gutterBottom>
                                    { resource.title }
                                </Typography>
                                <Typography variant="body2" color="textSecondary" paragraph>
                                    { resource.description }
                                </Typography>
                                {/* The button is absolutely positioned */ }
                                <Button
                                    variant="contained"
                                    color={ resource.buttonColor }
                                    disabled={ !resource.link }
                                    onClick={ () => handleDownload(resource.link) } // Use the download handler


                                    sx={ {
                                        position: 'absolute',
                                        bottom: 16,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: 'calc(100% - 32px)', // Adjust width to account for padding
                                    } }
                                >
                                    { resource.buttonText }
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                )) }
            </Grid>
        </Box>
    );
};

export default AdditionalResources;
