import React, { useEffect } from 'react';
import SubjectsCard from './SubjectsCard';
import instance from '../axios';
// import { subjectsData } from './path/to/subjectsData'; // Update the path to where your subjectsData is located

// const subjects = Object.keys(subjectsData).map(key => subjectsData[key]);

export default function SubjectsSection()
{
    const [subjects, setSubjects] = React.useState([]);
    useEffect(() =>
    {
        const fetchSubjects = async () =>
        {
            await instance.get('subjects').then((res) =>
            {
                console.log(res);
                setSubjects(res.data);
            });
        };
        fetchSubjects();
    }, []);

    console.log(subjects);
    if (!subjects || subjects.length === 0) return (
        <div>Loading...</div>
    );
    return (
        <div className="pb-16 rounded-xl shadow-xl relative overflow-hidden">
            <div className="absolute inset-0 -z-10 opacity-20">
                {/* <div className='h-44 w-44 bg-zinc-200'> */ }

                <img src="https://via.placeholder.com/1200x800?text=Background" alt="Background" className=" max-h-88 min-h-48 rounded-t-lg object-cover" />
            </div>
            <div className="text-center mb-12 relative">
                <h2 style={ {
                    background: 'linear-gradient(135deg, #00aaff, #66ff99)',
                    backgroundSize: '200% 200%',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    fontSize: '3rem', // Adjust as needed
                    fontWeight: 'bold', // Adjust as needed
                    animation: 'gradientAnimation 10s ease infinite',
                } } className="text-4xl font-bold pb-6 inline-block">
                    المواد
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-2/3 h-1 bg-gradient-to-r from-indigo-500 to-blue-500 rounded-full animate-pulse" />
                </h2>
                <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-br from-indigo-200 to-blue-200 opacity-20 z-0"></div>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16 w-4/5 m-auto p-auto place-items-center">
                { subjects.map((subject, index) => (
                    <SubjectsCard
                        key={ index }
                        title={ subject.arabicTitle }
                        id={ subject.id }
                        imgSrc={ subject.imageUrl } // Use imageUrl from subjectsData
                        description={ subject.description }
                    />
                )) }
            </div>
        </div>
    );
}