import { useEffect, useState } from 'react';
import { Button, TextField, Card, CardContent, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import instance from '../axios';

function SubjectsForm()
{
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [formData, setFormData] = useState({
        arabicTitle: '',
        englishTitle: '',
        description: '',
        level: '',
        duration: '',
        imageUrl: '',
        curriculumPdf: null,
        answerPdf: null,
        testPdf: null
    });

    useEffect(() =>
    {
        // Fetch subjects from the server
        instance.get('/subjects')
            .then(response =>
            {
                setSubjects(response.data);
            })
            .catch(error =>
            {
                console.error('Failed to fetch subjects:', error);
            });
    }, []);

    const handleSubjectChange = (e) =>
    {
        const subjectId = e.target.value;
        setSelectedSubject(subjectId);

        // Load selected subject data to form
        const selected = subjects.find(subject => subject.id === subjectId);
        if (selected)
        {
            setFormData({
                arabicTitle: selected.arabicTitle,
                englishTitle: selected.englishTitle,
                description: selected.description,
                level: selected.level,
                duration: selected.duration,
                imageUrl: selected.imageUrl,
                curriculumPdf: null,
                answerPdf: null,
                testPdf: null
            });
        }
    };

    const handleInputChange = (e) =>
    {
        const { name, value, type, files } = e.target;

        // Debugging: Check what is being passed into the function
        console.log(`Input name: ${name}, type: ${type}, value: ${value}, files: ${files}`);

        if (!name)
        {
            console.error("Input name is missing. Make sure all inputs have a 'name' attribute.");
            return;
        }

        if (type === 'file')
        {
            if (files && files.length > 0)
            {
                console.log(`File selected: ${files[0].name}`);  // Log the selected file name

                // Update the state with the selected file
                setFormData(prev => ({
                    ...prev,
                    [name]: files[0]  // Store the file object
                }));
            } else
            {
                console.warn("No file selected.");
            }
        } else
        {
            // Handle other input types (text, number, etc.)
            setFormData(prev => ({
                ...prev,
                [name]: value  // Update the form data state with the input value
            }));

            // Debugging: Log the updated state after change
            console.log(`Updated formData for ${name}:`, formData[name]);
        }
    };

    const handleSubmit = (e) =>
    {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('arabicTitle', formData.arabicTitle);
        formDataToSend.append('englishTitle', formData.englishTitle);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('level', formData.level);
        formDataToSend.append('duration', formData.duration);
        formDataToSend.append('imageUrl', formData.imageUrl);
        console.log(formData);
        if (formData.curriculumPdf) formDataToSend.append('curriculumPdf', formData.curriculumPdf);
        if (formData.answerPdf) formDataToSend.append('answerPdf', formData.answerPdf);
        if (formData.testPdf) formDataToSend.append('testPdf', formData.testPdf);

        console.log("Submitting form data: ", formDataToSend);

        instance.put(`/subjects/${selectedSubject}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',  // Required for file uploads
            },
        })
            .then(response =>
            {
                alert('Subject updated successfully');
                console.log('Subject updated:', response.data);
            })
            .catch(error =>
            {
                alert('Update failed');
                console.error('Failed to update subject:', error);
            });
    };

    return (
        <div className='mt-16'>
            <h2 className="text-4xl text-center mb-6">تحديث المادة</h2>
            <form onSubmit={ handleSubmit } className="space-y-6">
                <FormControl fullWidth required variant="outlined">
                    <InputLabel>اختر المادة</InputLabel>
                    <Select
                        value={ selectedSubject }
                        onChange={ handleSubjectChange }
                        label="اختر المادة"
                        className="bg-white"
                        MenuProps={ {
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                },
                            },
                        } }
                    >
                        { subjects.map(subject => (
                            <MenuItem key={ subject.id } value={ subject.id }>
                                { subject.arabicTitle }
                            </MenuItem>
                        )) }
                    </Select>
                </FormControl>

                <TextField
                    label="العنوان باللغة العربية"
                    name="arabicTitle"
                    value={ formData.arabicTitle }
                    onChange={ handleInputChange }
                    fullWidth
                    required
                />
                <TextField
                    label="العنوان باللغة الإنجليزية"
                    name="englishTitle"
                    value={ formData.englishTitle }
                    onChange={ handleInputChange }
                    fullWidth
                    required
                />
                <TextField
                    label="الوصف"
                    name="description"
                    value={ formData.description }
                    onChange={ handleInputChange }
                    fullWidth
                    multiline
                    rows={ 4 }
                    required
                />
                <TextField
                    label="المستوى"
                    name="level"
                    value={ formData.level }
                    onChange={ handleInputChange }
                    fullWidth
                    required
                />
                <TextField
                    label="المدة"
                    name="duration"
                    value={ formData.duration }
                    onChange={ handleInputChange }
                    fullWidth
                    required
                />
                <div className="flex gap-8">
                    <label>تحميل المنهج</label>
                    <input
                        type="file"
                        name="curriculumPdf"
                        accept="application/pdf"
                        onChange={ handleInputChange }
                    />
                </div>

                <div className="flex gap-8">
                    <label>تحميل ملف الحل</label>
                    <input
                        type="file"
                        name="answerPdf"
                        accept="application/pdf"
                        onChange={ handleInputChange }
                    />
                </div>

                <div className="flex gap-8">
                    <label>تحميل ملف الاختبار</label>
                    <input
                        type="file"
                        name="testPdf"
                        accept="application/pdf"
                        onChange={ handleInputChange }
                    />
                </div>
                <Button variant="contained" color="primary" type="submit">
                    تحديث المادة
                </Button>
            </form>
        </div>
    );
}

export default SubjectsForm;
