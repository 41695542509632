import '../App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import instance from '../axios';
import { useEffect, useState } from 'react';

import SubjectsSection from '../components/SubjectsSection';

async function checkAuthStatus()
{
  try
  {
    const response = await instance.get('/auth-status');
    return response.data;
  } catch (error)
  {
    console.error('Error checking authentication status:', error.response ? error.response.data : error.message);
    return { authenticated: false };
  }
}
function App()
{
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() =>
  {
    async function fetchAuthStatus()
    {
      await checkAuthStatus().then((res) =>
      {

        setIsAuthenticated(res.authenticated);
        console.log(res);
        setUser(res.user);
      });
    }


    fetchAuthStatus();
  }, []);
  const navigate = useNavigate();
  console.log('isAuthenticated', isAuthenticated, user);
  async function logout()
  {
    try
    {
      const response = await instance.delete('/logout', { withCredentials: true });
      console.log(response.data.message); // Logged out successfully
      // Optionally redirect the user or update the UI
    } catch (error)
    {
      console.error('Error logging out:', error.response ? error.response.data : error.message);
    }
  }


  const handleLogout = async () =>
  {
    await logout();
    navigate('/signin');
    // Optionally redirect the user or update the UI
  };



  const placeholderImage = "https://via.placeholder.com/600x400";

  const section1Text = {
    title: "التعليم هو المفتاح",
    content: "استكشف كيف يمكن أن يساعدك التعلم المستمر في تحقيق أهدافك.",
    buttonText: "اكتشف المزيد"
  };

  const section2Text = {
    title: "ابدأ رحلتك التعليمية",
    content: "استفد من مواردنا التعليمية المصممة لمساعدتك على النجاح.استفد من مواردنا التعليمية الشاملة والمصممة بعناية فائقة لمساعدتك على تحقيق النجاح الأكاديمي والمهني. نحن نقدم لك أدوات ومواد تعليمية مبتكرة تتناسب مع احتياجاتك وتدعم تطورك. ",
    buttonText: "ابدأ الآن"
  };

  document.addEventListener('contextmenu', (event) => {
    event.preventDefault();
});


  return (
    <div className="App">
      <div className=" relative" >
        <img src="/images/hero.png" alt="" className='h-full w-full' />
        {/* <div className="hero-content gap-16 flex flex-col items-center justify-center">
          <h1 className="hero-title">
            منصة رمزي
          </h1>
          <h2 className="hero-subtitle">
            المنصة الأفضل على الإطلاق لطلاب الثانوية
          </h2>
          <button className="cta-button"
            onClick={ () => document.getElementById('main-content').scrollIntoView({ behavior: 'smooth' }) }
          >
            ابدأ الآن
          </button>
        </div> */}


      </div>
      <div className="main-content " id="main-content" >
        <img src={ "/images/main-page-banner-2.png" } alt="Placeholder" className="w-full h-80 md:h-full rounded-lg shadow-lg object-fill" />
        <img src={ "/images/main-page-banner-1.jpg" } alt="Placeholder" className="w-full h-80 md:h-full rounded-lg shadow-lg object-fill" />

        <div className="relative my-16">
          <div className="absolute inset-x-0 bottom-0 h-0.5 bg-gradient-to-r from-blue-500 to-teal-500"></div>
          <div className="relative">
            <div className="border-t-2 border-gray-300"></div>
          </div>
        </div>
        <SubjectsSection />
        <div id="detail">
          <Outlet />
        </div>
        <img src={ "/images/footer-img.png" } alt="Placeholder" className="w-full h-[220px] md:h-screen rounded-lg shadow-lg object-fill" />

      </div>
    </div>
  );
}

export default App;
