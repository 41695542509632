import React, { useState } from 'react';
import instance from '../axios';

const CardGenerator = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    const handleGenerateCards = async () => {
        setLoading(true);
        setError(null);
        setMessage('');

        try {
            const response = await instance.post('/api/generate-cards', null, {
                responseType: 'blob', 
            });

       

                console.log(response)
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

            // Create a download link and trigger it
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'card-numbers.xlsx'); // Set the file name for download
            document.body.appendChild(link); // Append link to the DOM
            link.click(); // Simulate click to trigger the download
            link.remove(); // Remove the link from the DOM after download

            setMessage('تم إنشاء الأرقام بنجاح.');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='my-20 mx-auto'>
            <h1 className='textl-3xl'>آلة إنشاء الأرقام</h1>
            <button className='w-48 h-32 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={handleGenerateCards} disabled={loading}>
                {loading ? 'قيد الإنشاء...' : 'إنشاء الأرقام'}
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {message && <p style={{ color: 'green' }}>{message}</p>}
        </div>
    );
};

export default CardGenerator;
